.reviews-section {
  background-color: #EDD0BA;
  padding-top: 80px;
  padding-bottom: 80px;

  &__title {
    text-align: center;
    margin: 0 0 40px 0;
    font-size: 50px;
    line-height: 100%;
    font-family: $secondFont;
    font-weight: 700;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr minmax(0, 352px);
    grid-gap: 50px;
  }

  &__list {
    overflow: auto;
    scroll-behavior: smooth;
    max-height: 572px;
    scrollbar-width: thin;
    scrollbar-color: #845249 #FDF7F2;
    padding-right: 13px;
  }

  &__list::-webkit-scrollbar {
    width: 10px;
  }

  &__list::-webkit-scrollbar-track {
    background: #FDF7F2;
    border-radius: 30px;
  }

  &__list::-webkit-scrollbar-thumb {
    background-color: #845249;
    border-radius: 50px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 30px;

    &__wrap {
      grid-gap: 30px;
    }

    &__title {
      margin-bottom: 36px;
    }

    &__list {
      max-height: 938px;
    }
  }

  @media (max-width: 992px) {
    padding-bottom: 80px;

    &__list {
      padding-right: 6px;
    }

    &__wrap {
      grid-gap: 16px;
      grid-template-columns: 1fr minmax(0,336px);
    }
  }

  @media (max-width: 767px) {

    padding-top: 40px;
    padding-bottom: 40px;

    &__title {
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 20px;
    }

    &__wrap {
      grid-template-columns: auto;
      grid-gap: 35px;
    }

    &__list {
      order: 1;
      overflow: hidden;
      padding-right: 0;
      margin-right: -16px;
    }

    &__list .slick-list{padding:0 20% 0 0;}

    &__yandex {
      order: 0;
      overflow: hidden;
      background-color: #ffffff;
      border-radius: 20px;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    &__yandex div {
      position: relative;
      left: -7px;
      transform: scale(1.05);
    }
  }
}