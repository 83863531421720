.catalog-slider {

  &__slick {
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: 880px;
    padding-left: 75px;
    padding-right: 75px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  &__slick .slick-list {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }

  &__slick .slick-list {
    visibility: visible;
    animation: sliderInit 0.5s ease forwards; 
  }

  &__item img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    max-height: 456px;
  }

  &__tabs {
    max-width: 730px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 55px;
    margin-bottom: 33px;
  }

  &__tab-link {
    font-size: 13px;
    font-weight: 600;
    padding: 16px 43px;
    border: 2px solid #845249;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
  }
  
  &__tab-link:hover {
    border-color: #432723;
    background-color: transparent;
    color: #000000;
  }

  &__tab-link:active,
  &__tab-link.active,
  &__tab-link.active:hover,
  &__tab-link.slick-current {
    border-color: #845249;
    background-color: #845249;
    color: #ffffff;
  }

  &__info {
    display: flex;
    max-width: 880px;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 75px;
    padding-right: 75px;
    font-size: 20px;
    line-height: 24px;
  }

  &__btns {
    display: none;
  }

  .slick-prev {
    position: absolute;
    left: 0;
  }

  .slick-next {
    position: absolute;
    right: 0;
  }

  .slick-prev,
  .slick-next {
    top: 50%;
    margin-top: -22.5px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #845249;
    background-image: url("../img/assets/icon-slider-arrow.svg");
    background-repeat: no-repeat;
    background-position: 14px center;
    color: transparent;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background-color: #432723;
  }

  .slick-prev:active,
  .slick-next:active {
    background-color: #845249;
  }


  .slick-disabled,
  .slick-disabled.slick-next:active,
  .slick-disabled.slick-prev:active {
    background-color: #BDBDBD;
  }

  .slick-disabled:hover {
    background-color: #BDBDBD;
  }

  .slick-next {
    transform: rotate(-180deg);
  }

  @media (max-width: 1024px) {
    &__slick,
    &__info {
      max-width: 780px;
    }

    &__tabs {
      max-width: 630px;
      margin: 0 auto 36px;
    }

    &__tab-link {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  @media (max-width: 992px) {
    &__slick {
      padding: 0 76px;
      max-width: 706px;
    }

    &__tab-link {
      padding: 16px 30px;
      text-transform: uppercase;
    }
    
    &__tabs {
      grid-gap: 0 30px;
      max-width: 706px;
      padding: 0 75px;
    }
  }

  @media (max-width: 767px) {
    
    &__tabs {
      padding: 0;
      grid-gap: 0 8px;
      grid-template-columns: max-content max-content max-content;
      overflow: auto;
      display: flex;
      margin-bottom: 30px;
      margin-right: -16px;
    }

    &__tabs .slick-list {
      padding: 0 !important;
    }

    &__tab-link {
      font-size: 12px;
      line-height: 131.523%;
      padding: 8px 16px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &__slick {
      padding: 0;
    }

    &__btns {
      display: flex;
      justify-content: center;
    }

    &__info {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0;
      font-size: 16px;
      line-height: 19px;
    }

    &__next,
    &__prev {
      width: 28px;
      height: 28px;
      border: none;
      background-image: url("../img/assets/icon-slider-arrow-small.svg");
      background-color: #845249;
      background-position: center;
      background-repeat: no-repeat;
      color: transparent;
      border-radius: 100%;
      cursor: pointer;
      background-position: 10px;
    }

    &__prev {
      transform: rotate(-180deg);
      margin-right: 10px;
    }

    &__next:hover,
    &__prev:hover {
      background-color: #432723;
    }

    &__next:active,
    &__prev:active {
      background-color: #845249;
    }

    &__next.disabled,
    &__prev.disabled {
      background-color: #BDBDBD;
    }
    
  }
}


@keyframes sliderInit {   
  from {     
   opacity: 0;  
  }   
  to {
    opacity: 1;
  }
}