.contacts {
  padding-top: 38px;
  padding-bottom: 34px;

  &__wrap {
    display: grid;
    grid-template-columns: minmax(0, 289px) 1fr;
    grid-gap: 0 90px;
  }

  &__info p {
   margin: 14px 0; 
   line-height: 22px;
  }

  &__info a {
    text-decoration: none;
    color: #000000;
  }

  &__info p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    &__wrap {
      grid-gap: 0 46px;
    }
  }

  @media (max-width: 992px) {
    
    &__wrap {
      grid-template-columns: auto;
    }

    #yandex-map {
      height: 315px;
    }

    &__info {
      display: grid;
      justify-content: start;
      align-items: flex-end;
      grid-gap: 0 15px;
      margin-bottom: 54px;
    }

    &__logo {
      grid-column: 1/2;
      grid-row: 1/4;
    }

    p:nth-child(2) {
      grid-column: 4/2;
      max-width: 290px;
    }

    p:nth-child(3) {
      grid-column: 2/2;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 35px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    
    &__info {
      display: block;
      font-size: 14px;
      margin-bottom: 20px;
      position: relative;
    }

    &__info p {
      margin: 8px 0;
    }

    p:nth-child(2) {
      margin-top: 0;
      max-width: 220px;
    }

    &__logo {
      position: absolute;
      top: 0;
      right: 12px;
      width: 70px;
      height: 70px;
    }
  }

}