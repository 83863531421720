.navbar-top {
  &__top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__burger {
    margin-right: 16px;
    display: none;
  }

  &__logo {
    font-family: $secondFont;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    color: #FF151A;
  }

  &__btn-callback--mobile {
    display: none;
  }

  &__phones {
    font-size: 16px;
    font-family: $mainFont;
    position: relative;
    padding-left: 32px;
  }

  &__phones a {
    text-decoration: none;
    color: #000000;
  }

  &__phones::after {
    content: '';
    position: absolute;
    left: 0;
    background-image: url("../img/assets/icon-phone.svg");
    background-repeat: no-repeat;
    width: 23px;
    height: 22px;
  }

  @media (max-width: 992px) {
    &__btn-callback {
      width: 25px;
      height: 26px;
      color: transparent;
      padding: 0;
      background-image: url("../img/assets/icon-phone-bg.svg");
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: center;
      border: none;
    }

    &__btn-callback:hover {
      color: transparent;
    }
  }

  @media (max-width: 767px) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 3;

    &__logo {
      font-size: 20px;
    }

    &__phones {
      display: none;
    }

    &__btn-consulting {
      font-size: 12px;
      padding: 16px 22px;
      position: absolute;
      bottom: -52px;
      left: 24px;
      right: 24px;
    }

    &__btn-callback--mobile {
      display: block;
    }

    &__burger {
      display: block;
    }
  }
}