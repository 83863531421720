@font-face {
  font-family: "Cormorant Garamond";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvD-OzhOp_D95AnI.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2");
}
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.container {
  max-width: 1170px;
  min-width: 320px;
  padding: 0 30px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 992px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 375px) {
  .container {
    padding: 0 16px;
  }
}
@media (max-width: 375px) {
  .container--content {
    padding: 0 16px;
  }
}

.btn {
  display: inline-block;
  background-color: #FF151A;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 18px;
  border: 2px solid #FF151A;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}
.btn:hover {
  background-color: #CF080C;
  border-color: #CF080C;
}
.btn:hover::after {
  opacity: 1;
}

.project-input {
  border: none;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #000000;
  background-color: transparent;
  color: #333333;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 8px;
  width: 100%;
}
.project-input:focus {
  outline: none;
  border-width: 2px;
  color: #000000;
}
.project-input::placeholder {
  color: #333333;
}
.project-input--bg-dark::placeholder {
  color: #ffffff;
}
.project-input--bg-dark:focus, .project-input--bg-dark {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}
.project-input-error:focus, .project-input-error {
  border-bottom: 1px solid red;
}
@media (max-width: 767px) {
  .project-input {
    font-size: 14px;
  }
}

.burger {
  background-image: url("../img/assets/burger.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 20px;
  height: 28px;
  padding: 0;
}

.burger-close {
  background-image: url("../img/assets/icon-close-burger.svg");
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.header__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
}
.header__container {
  position: relative;
}
.header__logo {
  font-family: "Cormorant Garamond";
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  color: #FF151A;
}
.header__logo-round {
  position: absolute;
  top: 8px;
  right: 132px;
  animation: spin 12s linear infinite;
}
.header__offer {
  min-height: 552px;
  background-image: url("../img/assets/header.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top: 1px solid transparent;
  margin-top: -1px;
}
.header__navbar {
  background-color: #f6f6f6;
}
.header__navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.header__phones {
  font-size: 16px;
  font-family: "Inter";
  position: relative;
  padding-left: 32px;
}
.header__phones::after {
  content: "";
  position: absolute;
  left: 0;
  background-image: url("../img/assets/icon-phone.svg");
  background-repeat: no-repeat;
  width: 23px;
  height: 22px;
}
.header__title {
  color: #ffffff;
  font-size: 76px;
  font-family: "Cormorant Garamond";
  font-style: italic;
  font-weight: 700;
  line-height: 82.1%;
  padding: 30px 0 0;
  margin: 32px 0 18px;
}
.header__sub-title {
  font-size: 28px;
  font-family: "Inter";
  color: #ffffff;
  margin-bottom: 30px;
}
.header__descr {
  font-size: 16px;
  line-height: 22px;
  max-width: 424px;
  color: #ffffff;
  margin-bottom: 48px;
}
.header__btn-consulting {
  font-size: 13px;
  padding: 20px 29px;
  line-height: 19px;
  letter-spacing: 0.65px;
}
.header__btn-callback--mobile {
  display: none;
}
@media (max-width: 1024px) {
  .header__offer {
    min-height: 433px;
    background-image: url("../img/assets/header-1024.jpg");
    background-position: -15px;
  }
  .header__logo-round {
    top: 8px;
    right: 103px;
  }
  .header__logo-round img {
    width: 131px;
    height: 131px;
  }
  .header__descr {
    margin-bottom: 36px;
  }
  .header__sub-title {
    margin-bottom: 26px;
  }
  .header__btn-consulting {
    padding: 22px 36px;
  }
}
@media (max-width: 992px) {
  .header__btn-callback {
    width: 25px;
    height: 26px;
    color: transparent;
    padding: 0;
    background-image: url("../img/assets/icon-phone-bg.svg");
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .header__offer {
    background-image: url("../img/assets/header-768.jpg");
    background-position: center;
    min-height: 409px;
  }
  .header__title {
    margin-bottom: 27px;
    padding-top: 0;
    max-width: 660px;
  }
  .header__descr {
    margin-bottom: 46px;
  }
  .header__sub-title {
    margin-bottom: 17px;
    max-width: 406px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .header__title {
    max-width: 100%;
  }
  .header__logo-round {
    right: 68px;
    top: 71px;
  }
  .header__logo-round img {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .header__top-wrap {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header__logo {
    font-size: 20px;
  }
  .header__logo-round {
    right: 18px;
    top: -15px;
  }
  .header__logo-round img {
    width: 76px;
    height: 76px;
  }
  .header__phones {
    display: none;
  }
  .header__navbar {
    display: none;
  }
  .header__title {
    font-size: 39px;
    line-height: 78.6%;
    margin-bottom: 13px;
    max-width: 240px;
  }
  .header__descr {
    font-size: 14px;
    line-height: 129%;
    max-width: 260px;
  }
  .header__sub-title {
    font-size: 14px;
    line-height: 129%;
    margin-bottom: 13px;
  }
  .header__offer {
    background-image: url("../img/assets/header-320.jpg");
    background-position: bottom;
    min-height: 357px;
    position: relative;
  }
  .header__offer .container {
    padding: 0 24px;
  }
  .header__btn-consulting {
    font-size: 12px;
    padding: 14px 20px;
    position: absolute;
    bottom: -178px;
    left: 24px;
    right: 24px;
  }
  .header__btn-callback--mobile {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-top__top-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
}
.navbar-top__left {
  display: flex;
  align-items: center;
}
.navbar-top__burger {
  margin-right: 16px;
  display: none;
}
.navbar-top__logo {
  font-family: "Cormorant Garamond";
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  color: #FF151A;
}
.navbar-top__btn-callback--mobile {
  display: none;
}
.navbar-top__phones {
  font-size: 16px;
  font-family: "Inter";
  position: relative;
  padding-left: 32px;
}
.navbar-top__phones a {
  text-decoration: none;
  color: #000000;
}
.navbar-top__phones::after {
  content: "";
  position: absolute;
  left: 0;
  background-image: url("../img/assets/icon-phone.svg");
  background-repeat: no-repeat;
  width: 23px;
  height: 22px;
}
@media (max-width: 992px) {
  .navbar-top__btn-callback {
    width: 25px;
    height: 26px;
    color: transparent;
    padding: 0;
    background-image: url("../img/assets/icon-phone-bg.svg");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    border: none;
  }
  .navbar-top__btn-callback:hover {
    color: transparent;
  }
}
@media (max-width: 767px) {
  .navbar-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 3;
  }
  .navbar-top__logo {
    font-size: 20px;
  }
  .navbar-top__phones {
    display: none;
  }
  .navbar-top__btn-consulting {
    font-size: 12px;
    padding: 16px 22px;
    position: absolute;
    bottom: -52px;
    left: 24px;
    right: 24px;
  }
  .navbar-top__btn-callback--mobile {
    display: block;
  }
  .navbar-top__burger {
    display: block;
  }
}

.navbar-bottom {
  background-color: #f6f6f6;
}
.navbar-bottom__navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  min-width: auto;
}
.navbar-bottom__burger-close {
  display: none;
}
@media (max-width: 992px) {
  .navbar-bottom__btn-callback {
    width: 25px;
    height: 26px;
    color: transparent;
    padding: 0;
    background-image: url("../img/assets/icon-phone-bg.svg");
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    background-position: center;
  }
  .navbar-bottom__btn-callback:hover {
    color: transparent;
  }
}
@media (min-width: 767px) {
  .navbar-bottom {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
@media (max-width: 767px) {
  .navbar-bottom--mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    width: 260px;
    background-color: #432723;
    transform: translate(-100%);
    transition: transform 0.2s;
  }
  .navbar-bottom--mobile.active {
    transform: translate(0);
    z-index: 3;
  }
  .navbar-bottom__btn-callback {
    display: none;
  }
  .navbar-bottom__navbar-wrap {
    height: auto;
    padding: 30px 0 0 0;
  }
  .navbar-bottom__burger-close {
    display: block;
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

.nav-header {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}
.nav-header__item:not(:first-child) {
  padding-left: 60px;
}
.nav-header__link {
  text-decoration: none;
  color: #000000;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}
.nav-header__link:hover {
  color: #845249;
}
.nav-header__link:focus {
  color: #845249;
}
@media (max-width: 1024px) {
  .nav-header__item:not(:first-child) {
    padding-left: 45px;
  }
}
@media (max-width: 767px) {
  .nav-header {
    display: block;
    width: 100%;
  }
  .nav-header__item:not(:first-child) {
    padding-left: 0;
  }
  .nav-header__link {
    font-size: 12px;
    line-height: 131.523%;
    color: #ffffff;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .nav-header__link:active, .nav-header__link:hover {
    color: #EDD0BA;
  }
}

.features-top {
  background-color: #F6F6F6;
  padding-top: 20px;
  padding-bottom: 52px;
}
.features-top__item {
  position: relative;
  padding-left: 60px;
}
.features-top__item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../img/assets/icon-check-features.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #845249;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.features-top__wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 14%;
}
@media (max-width: 1024px) {
  .features-top__wrap {
    grid-gap: 0 80px;
  }
}
@media (max-width: 992px) {
  .features-top {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .features-top__wrap {
    grid-gap: 0 30px;
  }
}
@media (max-width: 767px) {
  .features-top {
    padding-top: 82px;
    padding-bottom: 48px;
  }
  .features-top__item {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
  .features-top__wrap {
    grid-template-columns: auto;
    grid-gap: 20px;
    padding: 0 24px;
  }
}

.about-section {
  padding-top: 80px;
  padding-bottom: 80px;
}
.about-section__title {
  margin: 0 0 30px 0;
  font-size: 50px;
  font-family: "Cormorant Garamond";
  font-style: italic;
  line-height: 50px;
}
.about-section__descr {
  font-size: 16px;
  line-height: 22px;
}
.about-section__wrap {
  display: grid;
  grid-template-columns: 336px auto;
  grid-gap: 45px;
  align-items: center;
}
.about-section__right {
  display: grid;
  grid-gap: 40px 50px;
  grid-template-columns: auto auto;
}
.about-section__item {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.about-section__item-caption {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 2px;
}
.about-section__item-descr {
  font-size: 16px;
  line-height: 19px;
}
.about-section__icon-wrap {
  width: 60px;
  height: 60px;
  background-color: #EDD0BA;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;
  flex-shrink: 0;
}
@media (max-width: 1024px) {
  .about-section__wrap {
    grid-gap: 30px;
    grid-template-columns: 300px auto;
  }
  .about-section__right {
    grid-gap: 40px 45px;
  }
}
@media (max-width: 992px) {
  .about-section__wrap {
    grid-gap: 40px;
    grid-template-columns: auto;
  }
  .about-section__right {
    grid-gap: 30px;
  }
}
@media (max-width: 767px) {
  .about-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .about-section__right {
    grid-template-columns: auto;
    grid-gap: 20px;
  }
  .about-section__title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .about-section__descr {
    font-size: 14px;
    line-height: 129%;
  }
  .about-section__wrap {
    grid-gap: 30px;
  }
  .about-section__item-caption {
    font-size: 14px;
  }
  .about-section__item-descr {
    font-size: 14px;
  }
  .about-section__icon-wrap {
    width: 45px;
    height: 45px;
  }
  .about-section__icon-wrap img {
    max-width: 25px;
  }
}

.catalog {
  background-color: #FDF7F2;
  padding-top: 80px;
  padding-bottom: 90px;
}
.catalog__title {
  margin: 0 0 30px 0;
  font-size: 50px;
  line-height: 50px;
  font-family: "Cormorant Garamond";
  font-style: italic;
  text-align: center;
}
@media (max-width: 1024px) {
  .catalog {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .catalog {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .catalog__title {
    font-size: 30px;
    line-height: 100%;
  }
}

.catalog-slider__slick {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 880px;
  padding-left: 75px;
  padding-right: 75px;
  margin: 0 auto;
  overflow: hidden;
}
.catalog-slider__slick .slick-list {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.catalog-slider__slick .slick-list {
  visibility: visible;
  animation: sliderInit 0.5s ease forwards;
}
.catalog-slider__item img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  max-height: 456px;
}
.catalog-slider__tabs {
  max-width: 730px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 55px;
  margin-bottom: 33px;
}
.catalog-slider__tab-link {
  font-size: 13px;
  font-weight: 600;
  padding: 16px 43px;
  border: 2px solid #845249;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}
.catalog-slider__tab-link:hover {
  border-color: #432723;
  background-color: transparent;
  color: #000000;
}
.catalog-slider__tab-link:active, .catalog-slider__tab-link.active, .catalog-slider__tab-link.active:hover, .catalog-slider__tab-link.slick-current {
  border-color: #845249;
  background-color: #845249;
  color: #ffffff;
}
.catalog-slider__info {
  display: flex;
  max-width: 880px;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 75px;
  padding-right: 75px;
  font-size: 20px;
  line-height: 24px;
}
.catalog-slider__btns {
  display: none;
}
.catalog-slider .slick-prev {
  position: absolute;
  left: 0;
}
.catalog-slider .slick-next {
  position: absolute;
  right: 0;
}
.catalog-slider .slick-prev,
.catalog-slider .slick-next {
  top: 50%;
  margin-top: -22.5px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #845249;
  background-image: url("../img/assets/icon-slider-arrow.svg");
  background-repeat: no-repeat;
  background-position: 14px center;
  color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}
.catalog-slider .slick-prev:hover,
.catalog-slider .slick-next:hover {
  background-color: #432723;
}
.catalog-slider .slick-prev:active,
.catalog-slider .slick-next:active {
  background-color: #845249;
}
.catalog-slider .slick-disabled,
.catalog-slider .slick-disabled.slick-next:active,
.catalog-slider .slick-disabled.slick-prev:active {
  background-color: #BDBDBD;
}
.catalog-slider .slick-disabled:hover {
  background-color: #BDBDBD;
}
.catalog-slider .slick-next {
  transform: rotate(-180deg);
}
@media (max-width: 1024px) {
  .catalog-slider__slick, .catalog-slider__info {
    max-width: 780px;
  }
  .catalog-slider__tabs {
    max-width: 630px;
    margin: 0 auto 36px;
  }
  .catalog-slider__tab-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
@media (max-width: 992px) {
  .catalog-slider__slick {
    padding: 0 76px;
    max-width: 706px;
  }
  .catalog-slider__tab-link {
    padding: 16px 30px;
    text-transform: uppercase;
  }
  .catalog-slider__tabs {
    grid-gap: 0 30px;
    max-width: 706px;
    padding: 0 75px;
  }
}
@media (max-width: 767px) {
  .catalog-slider__tabs {
    padding: 0;
    grid-gap: 0 8px;
    grid-template-columns: max-content max-content max-content;
    overflow: auto;
    display: flex;
    margin-bottom: 30px;
    margin-right: -16px;
  }
  .catalog-slider__tabs .slick-list {
    padding: 0 !important;
  }
  .catalog-slider__tab-link {
    font-size: 12px;
    line-height: 131.523%;
    padding: 8px 16px;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .catalog-slider__slick {
    padding: 0;
  }
  .catalog-slider__btns {
    display: flex;
    justify-content: center;
  }
  .catalog-slider__info {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 16px;
    line-height: 19px;
  }
  .catalog-slider__next, .catalog-slider__prev {
    width: 28px;
    height: 28px;
    border: none;
    background-image: url("../img/assets/icon-slider-arrow-small.svg");
    background-color: #845249;
    background-position: center;
    background-repeat: no-repeat;
    color: transparent;
    border-radius: 100%;
    cursor: pointer;
    background-position: 10px;
  }
  .catalog-slider__prev {
    transform: rotate(-180deg);
    margin-right: 10px;
  }
  .catalog-slider__next:hover, .catalog-slider__prev:hover {
    background-color: #432723;
  }
  .catalog-slider__next:active, .catalog-slider__prev:active {
    background-color: #845249;
  }
  .catalog-slider__next.disabled, .catalog-slider__prev.disabled {
    background-color: #BDBDBD;
  }
}

@keyframes sliderInit {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.consult-section {
  background-color: #432723;
  padding-top: 80px;
  padding-bottom: 80px;
}
.consult-section__title {
  font-family: "Cormorant Garamond";
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 24px;
}
.consult-section__descr {
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 48px;
  max-width: 470px;
}
.consult-section__policy {
  color: #ffffff;
  font-size: 12px;
  max-width: 451px;
}
.consult-section__wrap {
  display: grid;
  grid-template-columns: auto minmax(0, 344px);
  grid-gap: 0 160px;
}
@media (max-width: 1170px) {
  .consult-section__wrap {
    grid-gap: 0 80px;
  }
}
@media (max-width: 1024px) {
  .consult-section__wrap {
    grid-gap: 0 70px;
    grid-template-columns: minmax(0, 426px) 376px;
  }
  .consult-section__title {
    margin-bottom: 16px;
  }
  .consult-section__descr {
    margin-bottom: 26px;
  }
}
@media (max-width: 992px) {
  .consult-section__descr {
    margin-bottom: 30px;
  }
  .consult-section__wrap {
    grid-gap: 0 30px;
    grid-template-columns: minmax(0, 426px) 339px;
  }
}
@media (max-width: 767px) {
  .consult-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .consult-section__wrap {
    grid-template-columns: auto;
    display: block;
  }
  .consult-section__title {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 12px;
  }
  .consult-section__descr {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;
  }
  .consult-section__policy {
    margin-bottom: 30px;
  }
}

.form-consult__row {
  margin-bottom: 42px;
}
.form-consult__submit {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.65px;
  padding: 20px 18px;
  width: 100%;
}
.form-consult__submit.submit-success {
  background-color: #0DC143;
  border-color: #0DC143;
  color: transparent;
  background-image: url("../img/assets/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
@media (max-width: 767px) {
  .form-consult__submit {
    font-size: 12px;
    line-height: 19px;
    padding: 11px 18px;
  }
}

.form-consult-questions {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 60px 60px 60px;
}
.form-consult-questions__title {
  font-family: "Cormorant Garamond";
  font-weight: 700;
  font-style: italic;
  font-size: 50px;
  line-height: 100%;
  margin-bottom: 20px;
}
.form-consult-questions__descr {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 40px;
}
.form-consult-questions__row {
  margin-bottom: 30px;
}
.form-consult-questions__row input {
  padding-bottom: 12px;
}
.form-consult-questions__policy {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 32px;
}
.form-consult-questions__policy a {
  color: #845249;
}
.form-consult-questions__submit {
  padding: 20px 62px;
  font-weight: 600;
}
.form-consult-questions__submit.submit-success {
  background-color: #0DC143;
  border-color: #0DC143;
  color: transparent;
  background-image: url("../img/assets/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
@media (max-width: 1024px) {
  .form-consult-questions {
    padding: 40px 56px 56px;
  }
}
@media (max-width: 767px) {
  .form-consult-questions {
    padding: 20px;
  }
  .form-consult-questions__title {
    font-size: 30px;
    line-height: 100%;
    max-width: 228px;
  }
  .form-consult-questions__descr {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 30px;
  }
  .form-consult-questions__row {
    margin-bottom: 20px;
  }
  .form-consult-questions__policy {
    margin-top: 30px;
  }
  .form-consult-questions__submit {
    font-size: 12px;
    line-height: 16px;
    padding: 14px 62px;
    width: 100%;
  }
}

.work-example {
  padding-top: 74px;
  padding-bottom: 82px;
}
.work-example__title {
  font-family: "Cormorant Garamond";
  text-align: center;
  font-size: 50px;
  line-height: 50px;
  margin-top: 0;
  margin-bottom: 40px;
}
.work-example__wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 42px 30px;
}
.work-example__item {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  touch-action: none;
  cursor: pointer;
}
.work-example__item:hover .work-example__img-wrap::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.work-example__img-wrap {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 9px;
  pointer-events: none;
  position: relative;
}
.work-example__img-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}
.work-example__img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  display: block;
}
.work-example__name {
  text-align: center;
}
@media (max-width: 992px) {
  .work-example {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .work-example__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .work-example {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .work-example__title {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .work-example__wrap {
    grid-template-columns: auto;
    grid-gap: 20px;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}
.modal__inner {
  max-width: 100px;
  min-width: 240px;
  max-height: 100%;
  left: 20px;
  right: 20px;
  background: #fff;
  position: absolute;
  z-index: 1;
  margin: auto;
  padding: 0;
  border-radius: 5px;
  overflow: auto;
  scroll-behavior: smooth;
}
.modal-open {
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.2s;
  opacity: 1;
  visibility: visible;
  z-index: 3;
}
.modal-close {
  width: 28px;
  height: 28px;
  background-image: url("../img/assets/icon-close-modal.svg");
  position: absolute;
  border: none;
  right: 15px;
  top: 15px;
  background-color: transparent;
  cursor: pointer;
}
.modal--modal-callback .modal__inner {
  max-width: 540px;
}
.modal--work-example .modal__inner {
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .modal--work-example .modal-close {
    background-image: url("../img/assets/icon-arrow-back.svg");
    left: 15px;
    right: auto;
  }
  .modal--work-example .modal__inner {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.work-categories {
  padding-bottom: 100px;
}
.work-categories__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin: 0 auto;
  max-width: 1110px;
}
.work-categories__item {
  display: block;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.work-categories__item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}
.work-categories__item:hover::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.work-categories__item img {
  max-width: 100%;
  display: block;
  pointer-events: none;
}
.work-categories__title {
  font-size: 50px;
  font-family: "Cormorant Garamond";
  font-style: italic;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  margin-top: 54px;
  margin-bottom: 43px;
}
@media (max-width: 992px) {
  .work-categories__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .work-categories__title {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .work-categories__content {
    grid-template-columns: auto;
  }
}

.work-slider {
  background-color: #fff;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  border-radius: 5px;
  padding-bottom: 80px;
  padding-top: 68px;
}
.work-slider.open {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s;
}
.work-slider__wrap {
  padding: 0 75px;
  max-width: 880px;
  max-height: 456px;
  overflow: hidden;
  margin: 0 auto;
}
.work-slider__item {
  overflow: hidden;
  border-radius: 5px;
}
.work-slider__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  max-height: 456px;
}
.work-slider__close {
  width: 28px;
  height: 28px;
  background-image: url("../img/assets/icon-close-modal.svg");
  position: absolute;
  border: none;
  right: 15px;
  top: 15px;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
}
.work-slider .slick-prev {
  position: absolute;
  left: 0;
}
.work-slider .slick-next {
  position: absolute;
  right: 0;
}
.work-slider .slick-prev,
.work-slider .slick-next {
  top: 50%;
  margin-top: -22.5px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #845249;
  background-image: url("../img/assets/icon-slider-arrow.svg");
  background-repeat: no-repeat;
  background-position: 14px center;
  color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}
.work-slider .slick-disabled {
  background-color: #BDBDBD;
}
.work-slider .slick-next {
  transform: rotate(-180deg);
}
@media (max-width: 767px) {
  .work-slider {
    margin-left: -19px;
    margin-right: -30px;
  }
  .work-slider__close {
    left: 15px;
    right: auto;
    background-image: url("../img/assets/icon-arrow-back.svg");
    background-color: #fff;
  }
  .work-slider__wrap {
    padding: 12px 0 0 0;
  }
  .work-slider__item {
    margin: 0 5px;
  }
  .work-slider__item img {
    height: 385px;
  }
}

.calling-designer {
  background-color: #FDF7F2;
}
.calling-designer__wrap {
  position: relative;
  padding-top: 80px;
  padding-bottom: 68px;
  padding-right: 370px;
}
.calling-designer__content {
  max-width: 820px;
  min-height: 223px;
  margin-bottom: 46px;
}
.calling-designer__title {
  font-size: 50px;
  line-height: 100%;
  font-family: "Cormorant Garamond";
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}
.calling-designer__descr {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 45px;
}
.calling-designer__form {
  max-width: 696px;
  min-height: 207px;
}
.calling-designer__form::after {
  content: "";
  display: block;
  background-image: url("../img/vendor/consult-photo.png");
  background-repeat: no-repeat;
  width: 383px;
  height: 554px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.calling-designer__features {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: minmax(0, 197px) minmax(0, 202px) 1fr;
  grid-gap: 0 50px;
}
.calling-designer__features-item {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  padding-left: 56px;
}
.calling-designer__features-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../img/assets/icon-check-features.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #845249;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
@media (max-width: 1024px) {
  .calling-designer__content {
    max-width: none;
    margin-bottom: 110px;
  }
  .calling-designer__wrap {
    padding-right: 30px;
    padding-bottom: 135px;
  }
  .calling-designer__title {
    margin-bottom: 25px;
  }
  .calling-designer__descr {
    margin-bottom: 52px;
  }
  .calling-designer__features {
    grid-gap: 0 60px;
    grid-template-columns: minmax(0, 207px) minmax(0, 284px) minmax(0, 294px);
  }
  .calling-designer__form {
    max-width: 467px;
    margin-right: 497px;
  }
  .calling-designer__form::after {
    right: 70px;
    width: 442px;
    height: 589px;
    background-image: url("../img/vendor/consult-photo-1024.png");
  }
}
@media (max-width: 992px) {
  .calling-designer__wrap {
    padding-bottom: 50px;
  }
  .calling-designer__title {
    margin-bottom: 27px;
    max-width: 636px;
  }
  .calling-designer__content {
    margin-bottom: 37px;
  }
  .calling-designer__form {
    max-width: 338px;
    margin-right: 350px;
  }
  .calling-designer__form::after {
    background-image: url("../img/vendor/consult-photo-768.png");
    width: 354px;
    height: 458px;
    right: 58px;
  }
}
@media (max-width: 767px) {
  .calling-designer__wrap {
    padding-right: 16px;
    padding-top: 40px;
  }
  .calling-designer__title {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .calling-designer__descr {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .calling-designer__features {
    grid-template-columns: auto;
  }
  .calling-designer__features-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .calling-designer__content {
    margin-bottom: 0;
  }
  .calling-designer__form {
    margin-right: 0;
    position: relative;
    padding-top: 286px;
  }
  .calling-designer__form::after {
    background-image: url("../img/vendor/consult-photo-767.png");
    width: 212px;
    height: 322px;
    right: 0;
    left: 0;
    margin: auto;
    bottom: auto;
    top: 0;
    z-index: 1;
  }
}

.form-calling-designer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
}
.form-calling-designer__policy {
  font-size: 12px;
  line-height: 16px;
  align-self: self-end;
}
.form-calling-designer__policy a {
  color: #845249;
}
.form-calling-designer__submit {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.65px;
  padding: 20px 18px;
  width: 100%;
}
.form-calling-designer__submit.submit-success {
  background-color: #0DC143;
  border-color: #0DC143;
  color: transparent;
  background-image: url("../img/assets/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
@media (max-width: 1024px) {
  .form-calling-designer {
    grid-template-columns: auto;
    grid-gap: 0;
  }
  .form-calling-designer__row {
    margin-bottom: 40px;
  }
  .form-calling-designer__policy {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .form-calling-designer {
    position: relative;
    z-index: 2;
    padding: 20px 20px 30px 20px;
  }
  .form-calling-designer__row {
    margin-bottom: 20px;
  }
  .form-calling-designer__policy {
    margin-bottom: 20px;
  }
  .form-calling-designer__submit {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 14px 18px;
    width: 100%;
  }
}

.reviews-section {
  background-color: #EDD0BA;
  padding-top: 80px;
  padding-bottom: 80px;
}
.reviews-section__title {
  text-align: center;
  margin: 0 0 40px 0;
  font-size: 50px;
  line-height: 100%;
  font-family: "Cormorant Garamond";
  font-weight: 700;
}
.reviews-section__wrap {
  display: grid;
  grid-template-columns: 1fr minmax(0, 352px);
  grid-gap: 50px;
}
.reviews-section__list {
  overflow: auto;
  scroll-behavior: smooth;
  max-height: 572px;
  scrollbar-width: thin;
  scrollbar-color: #845249 #FDF7F2;
  padding-right: 13px;
}
.reviews-section__list::-webkit-scrollbar {
  width: 10px;
}
.reviews-section__list::-webkit-scrollbar-track {
  background: #FDF7F2;
  border-radius: 30px;
}
.reviews-section__list::-webkit-scrollbar-thumb {
  background-color: #845249;
  border-radius: 50px;
}
@media (max-width: 1024px) {
  .reviews-section {
    padding-bottom: 30px;
  }
  .reviews-section__wrap {
    grid-gap: 30px;
  }
  .reviews-section__title {
    margin-bottom: 36px;
  }
  .reviews-section__list {
    max-height: 938px;
  }
}
@media (max-width: 992px) {
  .reviews-section {
    padding-bottom: 80px;
  }
  .reviews-section__list {
    padding-right: 6px;
  }
  .reviews-section__wrap {
    grid-gap: 16px;
    grid-template-columns: 1fr minmax(0, 336px);
  }
}
@media (max-width: 767px) {
  .reviews-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .reviews-section__title {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .reviews-section__wrap {
    grid-template-columns: auto;
    grid-gap: 35px;
  }
  .reviews-section__list {
    order: 1;
    overflow: hidden;
    padding-right: 0;
    margin-right: -16px;
  }
  .reviews-section__list .slick-list {
    padding: 0 20% 0 0;
  }
  .reviews-section__yandex {
    order: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .reviews-section__yandex div {
    position: relative;
    left: -7px;
    transform: scale(1.05);
  }
}

.reviews-item {
  background-color: #fff;
  padding: 24px;
  border-radius: 5px;
  overflow: hidden;
}
.reviews-item__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.reviews-item__date {
  font-size: 12px;
  line-height: 15px;
}
.reviews-item:not(:last-child) {
  margin-bottom: 20px;
}
.reviews-item__author {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
.reviews-item__text {
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 767px) {
  .reviews-item {
    margin-right: 16px;
    padding: 16px;
    width: 240px !important;
  }
  .reviews-item__author {
    font-size: 14px;
  }
  .reviews-item__top {
    margin-bottom: 12px;
  }
}

.delivery {
  padding-top: 80px;
  padding-bottom: 80px;
}
.delivery__wrap {
  display: grid;
  grid-gap: 20px 33px;
}
.delivery__title {
  grid-row: 1/2;
  grid-column: 1/4;
  font-size: 50px;
  font-family: "Cormorant Garamond";
  line-height: 100%;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}
.delivery__info {
  grid-column: 1/4;
  grid-row: 4;
  display: grid;
  grid-template-columns: minmax(0, 312px) minmax(0, 422px);
  margin-top: 16px;
  grid-gap: 0 67px;
}
.delivery__info-item p {
  font-size: 12px;
  line-height: 15px;
  margin: 4px 0;
}
@media (max-width: 1024px) {
  .delivery__wrap {
    grid-gap: 25px 30px;
    grid-template-columns: minmax(0, 301px) minmax(0, 276px) minmax(0, 301px);
  }
  .delivery__info {
    margin-top: 4px;
    grid-gap: 0 20px;
  }
}
@media (max-width: 992px) {
  .delivery__wrap {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  .delivery__title, .delivery__info {
    grid-column: 1/3;
  }
  .delivery__info {
    grid-row: 5;
    grid-gap: 0 30px;
  }
}
@media (max-width: 767px) {
  .delivery {
    padding-top: 40px;
    padding-bottom: 52px;
  }
  .delivery__title {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  .delivery__title, .delivery__info {
    grid-column: 1;
  }
  .delivery__wrap {
    grid-gap: 20px;
    grid-template-columns: auto;
  }
  .delivery__info {
    grid-row: 8;
    margin-top: -12px;
    grid-template-columns: auto;
  }
  .delivery__info-item:not(:last-child) {
    margin-bottom: 8px;
  }
}

.delivery-item__title {
  font-size: 16px;
  font-weight: 700;
}
.delivery-item__descr p {
  margin: 8px 0;
  color: #333333;
}
.delivery-item {
  padding-left: 56px;
  position: relative;
}
.delivery-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../img/assets/icon-check-features.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #845249;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
@media (max-width: 767px) {
  .delivery-item {
    padding-left: 46px;
  }
  .delivery-item::after {
    width: 30px;
    height: 30px;
    background-size: 13px;
  }
  .delivery-item__title {
    font-size: 14px;
    margin-bottom: -6px;
  }
  .delivery-item__descr {
    font-size: 14px;
  }
}

.consult-bottom {
  background-color: #F2F2F2;
  overflow: hidden;
  padding-top: 60px;
}
.consult-bottom__wrap {
  background-image: url("../img/assets/consult-bg.jpg");
  background-repeat: no-repeat;
  background-position: left bottom;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.consult-bottom__form {
  max-width: 540px;
  margin-bottom: -10px;
}
@media (max-width: 1024px) {
  .consult-bottom__form {
    max-width: 493px;
    margin-bottom: -25px;
  }
}
@media (max-width: 992px) {
  .consult-bottom {
    padding-top: 0;
  }
  .consult-bottom__wrap {
    background-image: url("../img/assets/consult-bg-768.jpg");
    padding-top: 355px;
    background-position: top right;
  }
  .consult-bottom__form {
    max-width: 582px;
    margin-bottom: -2px;
    border-radius: 10px 10px 0 0;
  }
}
@media (max-width: 767px) {
  .consult-bottom__wrap {
    display: block;
    background-image: url("../img/assets/consult-bg-767.jpg");
    padding-top: 118px;
    padding-bottom: 30px;
  }
}

.contacts {
  padding-top: 38px;
  padding-bottom: 34px;
}
.contacts__wrap {
  display: grid;
  grid-template-columns: minmax(0, 289px) 1fr;
  grid-gap: 0 90px;
}
.contacts__info p {
  margin: 14px 0;
  line-height: 22px;
}
.contacts__info a {
  text-decoration: none;
  color: #000000;
}
.contacts__info p:last-child {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .contacts__wrap {
    grid-gap: 0 46px;
  }
}
@media (max-width: 992px) {
  .contacts__wrap {
    grid-template-columns: auto;
  }
  .contacts #yandex-map {
    height: 315px;
  }
  .contacts__info {
    display: grid;
    justify-content: start;
    align-items: flex-end;
    grid-gap: 0 15px;
    margin-bottom: 54px;
  }
  .contacts__logo {
    grid-column: 1/2;
    grid-row: 1/4;
  }
  .contacts p:nth-child(2) {
    grid-column: 4/2;
    max-width: 290px;
  }
  .contacts p:nth-child(3) {
    grid-column: 2/2;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 35px;
  }
}
@media (max-width: 767px) {
  .contacts {
    padding-top: 40px;
  }
  .contacts__info {
    display: block;
    font-size: 14px;
    margin-bottom: 20px;
    position: relative;
  }
  .contacts__info p {
    margin: 8px 0;
  }
  .contacts p:nth-child(2) {
    margin-top: 0;
    max-width: 220px;
  }
  .contacts__logo {
    position: absolute;
    top: 0;
    right: 12px;
    width: 70px;
    height: 70px;
  }
}

.footer {
  background-color: #432723;
  padding-top: 18px;
  padding-bottom: 18px;
}
.footer__wrap {
  display: grid;
  grid-template-columns: minmax(0, 289px) 1fr;
  grid-gap: 0 90px;
}
.footer__copyright, .footer a {
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-decoration: none;
}
.footer a:not(:first-child) {
  margin-left: 45px;
}
.footer a:hover {
  color: #EDD0BA;
}
@media (max-width: 1024px) {
  .footer__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 0;
  }
}
@media (max-width: 992px) {
  .footer__wrap {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    grid-gap: 50px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .footer__wrap {
    grid-template-columns: auto;
    grid-gap: 0;
  }
  .footer__copyright {
    font-size: 12px;
    line-height: 18px;
  }
  .footer__policy {
    font-size: 12px;
    line-height: 18px;
  }
  .footer__policy a {
    display: inline-block;
  }
  .footer__policy a:not(:first-child) {
    margin-left: 0;
  }
}