@charset "UTF-8";

*,
*::before,
*::after {
  box-sizing: border-box;
}


html {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: $mainFont;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.container {
  max-width: 1170px;
  min-width: 320px;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: $bigTbWidth) {
    padding: 0 30px;
  }  
  
  @media (max-width: $tabletWidth) {
    padding: 0 30px;
  }


  @media (max-width: $phoneWidthMob) {
    padding: 0 16px;
  }

  @media (max-width: $phoneWidthMob) {
    &--content {
      padding: 0 16px;
    }
  }
}
