.work-example {
  padding-top: 74px;
  padding-bottom: 82px;

  &__title {
    font-family: 'Cormorant Garamond';
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 42px 30px;
  }

  &__item {
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    touch-action: none;
    cursor: pointer;
  }

  &__item:hover &__img-wrap::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__img-wrap {
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 9px;
    pointer-events: none;
    position: relative;
  }

  &__img-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all .2s;
  }

  &__img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    display: block;
  }

  &__name {
    text-align: center;
  }
  
  @media (max-width: 992px) {
    padding-top: 80px;
    padding-bottom: 60px;

    &__wrap {
      grid-template-columns: repeat(2,1fr);
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;

    &__title {
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 20px;
    }

    &__wrap {
      grid-template-columns: auto;
      grid-gap: 20px;
    }
  }
}