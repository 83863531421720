.about-section {
  padding-top: 80px;
  padding-bottom: 80px;

  &__title {
    margin: 0 0 30px 0;
    font-size: 50px;
    font-family: "Cormorant Garamond";
    font-style: italic;
    line-height: 50px;
  }

  &__descr {
    font-size: 16px;
    line-height: 22px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 336px auto;
    grid-gap: 45px;
    align-items: center;
  }

  &__right {
    display: grid;
    grid-gap: 40px 50px;
    grid-template-columns: auto auto;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-right: 15px;
  }

  &__item-caption {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  &__item-descr {
    font-size: 16px;
    line-height: 19px;
  }

  &__icon-wrap {
    width: 60px;
    height: 60px;
    background-color: #EDD0BA;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 21px;
    flex-shrink: 0;
  }

  @media (max-width: 1024px) {

    &__wrap {
      grid-gap: 30px;
      grid-template-columns: 300px auto;
    }

    &__right {
      grid-gap: 40px 45px;
    }
  }

  @media (max-width: 992px) {

    &__wrap {
      grid-gap: 40px;
      grid-template-columns: auto;
    }

    &__right {
      grid-gap: 30px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;

    &__right {
      grid-template-columns: auto;
      grid-gap: 20px;
    }

    &__title {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    &__descr {
      font-size: 14px;
      line-height: 129%;
    }

    &__wrap {
      grid-gap: 30px;
    }

    &__item-caption {
      font-size: 14px;
    }

    &__item-descr {
      font-size: 14px;
    }

    &__icon-wrap {
      width: 45px;
      height: 45px;
    }

    &__icon-wrap img {
      max-width: 25px;
    }
  }
}