.delivery-item {

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__descr p {
    margin: 8px 0;
    color: #333333;
  }

  & {
    padding-left: 56px;
    position: relative;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../img/assets/icon-check-features.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #845249;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  @media (max-width: 767px) {

    padding-left: 46px;
    
    &::after {
      width: 30px;
      height: 30px;
      background-size: 13px;
    }

    &__title  {
      font-size: 14px;
      margin-bottom: -6px;
    }

    &__descr {
      font-size: 14px;
    }
  }
}