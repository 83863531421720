.burger {
  background-image: url("../img/assets/burger.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 20px;
  height: 28px;
  padding: 0;
}