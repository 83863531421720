.consult-section {
  background-color: #432723;
  padding-top: 80px;
  padding-bottom: 80px;

  &__title {
    font-family: 'Cormorant Garamond';
    font-size: 50px;
    line-height: 50px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &__descr {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 48px;
    max-width: 470px;
  }

  &__policy {
    color: #ffffff;
    font-size: 12px;
    max-width: 451px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: auto minmax(0, 344px);
    grid-gap: 0 160px;
  }

  @media (max-width: 1170px) {
    &__wrap {
      grid-gap: 0 80px;
    }
  }

  @media (max-width: 1024px) {
    &__wrap {
      grid-gap: 0 70px;
      grid-template-columns: minmax(0, 426px) 376px;
    }

    &__title {
      margin-bottom: 16px;
    }

    &__descr {
      margin-bottom: 26px;
    }
  }

  @media (max-width: 992px) {
    &__descr {
      margin-bottom: 30px;
    }

    &__wrap {
      grid-gap: 0 30px;
      grid-template-columns: minmax(0, 426px) 339px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;

    &__wrap {
      grid-template-columns: auto;
      display: block;
    }

    &__title {
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 12px;
    }

    &__descr {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 18px;
    }

    &__policy {
      margin-bottom: 30px;
    }
  }
}