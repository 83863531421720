.catalog {
  background-color: #FDF7F2;
  padding-top: 80px;
  padding-bottom: 90px;


  &__title {
    margin: 0 0 30px 0;
    font-size: 50px;
    line-height: 50px;
    font-family: "Cormorant Garamond";
    font-style: italic;
    text-align: center;
  }

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
    
    &__title {
      font-size: 30px;
      line-height: 100%;
    }
  }
}