.btn {
  display: inline-block;
  background-color: #FF151A;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 18px;
  border: 2px solid #FF151A;
  border-radius: 5px;
  transition: background-color .2s;
  cursor: pointer;
  position: relative;
  
  &:hover {
    background-color: #CF080C;
    border-color: #CF080C;
  }

  &:hover::after {
    opacity: 1;
  }
}