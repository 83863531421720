.features-top {
  background-color: #F6F6F6;
  padding-top: 20px;
  padding-bottom: 52px;

  &__item {
    position: relative;
    padding-left: 60px;
  }

  &__item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../img/assets/icon-check-features.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #845249;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 14%;
  }

  @media (max-width: 1024px) {
    &__wrap {
      grid-gap: 0 80px;
    }
  }

  @media (max-width: 992px) {
    padding-top: 25px;
    padding-bottom: 25px;

    &__wrap {
      grid-gap: 0 30px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 82px;
    padding-bottom: 48px;

    &__item {
      min-height: 40px;
      display: flex;
      align-items: center;
    }

    &__wrap {
      grid-template-columns: auto;
      grid-gap: 20px;
      padding: 0 24px;
    }
  }
}