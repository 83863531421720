.delivery {
  padding-top: 80px;
  padding-bottom: 80px;

  &__wrap {
    display: grid;
    grid-gap: 20px 33px;
  }

  &__title {
    grid-row: 1/2;
    grid-column: 1/4;
    font-size: 50px;
    font-family: $secondFont;
    line-height: 100%;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__info {
    grid-column: 1/4;
    grid-row: 4;
    display: grid;
    grid-template-columns: minmax(0, 312px) minmax(0, 422px);
    margin-top: 16px;
    grid-gap: 0 67px;
  }

  &__info-item p {
    font-size: 12px;
    line-height: 15px;
    margin: 4px 0;
  }

  @media (max-width: 1024px) {

    &__wrap {
      grid-gap: 25px 30px;
      grid-template-columns: minmax(0, 301px) minmax(0, 276px) minmax(0, 301px);
    }

    &__info {
      margin-top: 4px;
      grid-gap: 0 20px;
    }
  }

  @media (max-width: 992px) {

    &__wrap {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }

    &__title,
    &__info {
      grid-column: 1/3;
    }

    &__info {
      grid-row: 5;
      grid-gap: 0 30px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 52px;
    
    &__title {
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 0;
      text-align: center;
    }

    &__title,
    &__info {
      grid-column: 1;
    }

    &__wrap {
      grid-gap: 20px;
      grid-template-columns: auto;
    }

    &__info {
      grid-row: 8;
      margin-top: -12px;
      grid-template-columns: auto;
    }

    &__info-item:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}