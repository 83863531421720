.project-input {
  border: none;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #000000;
  background-color: transparent;
  color: #333333;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 8px;
  width: 100%;

  &:focus {
    outline: none;
    border-width: 2px;
    color: #000000;
  }

  &::placeholder {
    color: #333333;
  }

  &--bg-dark::placeholder {
    color: #ffffff;
  }

  &--bg-dark:focus,
  &--bg-dark {
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
  }

  &-error:focus,
  &-error {
    border-bottom: 1px solid red;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}