.navbar-bottom {
  background-color: #f6f6f6;

  &__navbar-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    min-width: auto;
  }

  &__burger-close {
    display: none;
  }

  @media (max-width: 992px) {
    &__btn-callback {
      width: 25px;
      height: 26px;
      color: transparent;
      padding: 0;
      background-image: url("../img/assets/icon-phone-bg.svg");
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
      background-position: center;
    }

    &__btn-callback:hover {
      color: transparent;
    }
  }

  @media (min-width: 767px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  @media (max-width: 767px) {
    &--mobile {
      position: fixed;
      z-index: 3;
      top: 0;
      bottom: 0;
      width: 260px;
      background-color: #432723;
      transform: translate(-100%);
      transition: transform .2s;
    }

    &--mobile.active {
      transform: translate(0);
      z-index: 3;
    }

    &__btn-callback {
      display: none;
    }

    &__navbar-wrap {
      height: auto;
      padding: 30px 0 0 0;
    }

    &__burger-close {
      display: block;
      position: absolute;
      top: 9px;
      right: 9px;
    }
  }
}