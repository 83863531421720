.header {
  &__top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
  }

  &__container {
    position: relative;
  }

  &__logo {
    font-family: $secondFont;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    color: #FF151A;
  }

  &__logo-round {
    position: absolute;
    top: 8px;
    right: 132px;
    animation: spin 12s linear infinite
  }

  &__offer {
    min-height: 552px;
    background-image: url("../img/assets/header.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top: 1px solid transparent;
    margin-top: -1px;
  }

  &__navbar {
    background-color: #f6f6f6;
  }

  &__navbar-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  &__phones {
    font-size: 16px;
    font-family: $mainFont;
    position: relative;
    padding-left: 32px;
  }

  &__phones::after {
    content: '';
    position: absolute;
    left: 0;
    background-image: url("../img/assets/icon-phone.svg");
    background-repeat: no-repeat;
    width: 23px;
    height: 22px;
  }

  &__title {
    color: #ffffff;
    font-size: 76px;
    font-family: "Cormorant Garamond";
    font-style: italic;
    font-weight: 700;
    line-height: 82.1%;
    padding: 30px 0 0;
    margin: 32px 0 18px;
  }

  &__sub-title {
    font-size: 28px;
    font-family: $mainFont;
    color: #ffffff;
    margin-bottom: 30px;
  }

  &__descr {
    font-size: 16px;
    line-height: 22px;
    max-width: 424px;
    color: #ffffff;
    margin-bottom: 48px;
  }

  &__btn-consulting {
    font-size: 13px;
    padding: 20px 29px;
    line-height: 19px;
    letter-spacing: 0.65px;
  }

  &__btn-callback--mobile {
    display: none;
  }

  @media (max-width: 1024px) {
    &__offer {
      min-height: 433px;
      background-image: url("../img/assets/header-1024.jpg");
      background-position: -15px;
    }

    &__logo-round {
      top: 8px;
      right: 103px;
    }

    &__logo-round img {
      width: 131px;
      height: 131px;
    }

    &__descr {
      margin-bottom: 36px;
    }

    &__sub-title {
      margin-bottom: 26px;
    }

    &__btn-consulting {
      padding: 22px 36px;
    }
  }

  @media (max-width: 992px) {
    &__btn-callback {
      width: 25px;
      height: 26px;
      color: transparent;
      padding: 0;
      background-image: url("../img/assets/icon-phone-bg.svg");
      background-repeat: no-repeat;
      background-color: transparent;
    }

    &__offer {
      background-image: url("../img/assets/header-768.jpg");
      background-position: center;
      min-height: 409px;
    }

    &__title {
      margin-bottom: 27px;
      padding-top: 0;
      max-width: 660px;
    }

    &__descr {
      margin-bottom: 46px;
    }

    &__sub-title {
      margin-bottom: 17px;
      max-width: 406px;
      line-height: 34px;
    }
    
  }

  @media (max-width: 768px) {
    &__title {
      max-width: 100%;
    }

    &__logo-round {
      right: 68px;
      top: 71px;
    }

    &__logo-round img {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 767px) {

    &__top-wrap {
      padding-left: 12px;
      padding-right: 12px;
    }

    &__logo {
      font-size: 20px;
    }

    &__logo-round {
      right: 18px;
      top: -15px;
    }

    &__logo-round img {
      width: 76px;
      height: 76px;
    }

    &__phones {
      display: none;
    }

    &__navbar {
      display: none;
    }

    &__title {
      font-size: 39px;
      line-height: 78.6%;
      margin-bottom: 13px;
      max-width: 240px;
    }

    &__descr {
      font-size: 14px;
      line-height: 129%;
      max-width: 260px;
    }

    &__sub-title {
      font-size: 14px;
      line-height: 129%;
      margin-bottom: 13px;
    }

    &__offer {
      background-image: url("../img/assets/header-320.jpg");
      background-position: bottom;
      min-height: 357px;
      position: relative;
    }

    &__offer .container {
      padding: 0 24px;
    }

    &__btn-consulting {
      font-size: 12px;
      padding: 14px 20px;
      position: absolute;
      bottom: -178px;
      left: 24px;
      right: 24px;
    }

    &__btn-callback--mobile {
      display: block;
    }
    
  }
}

@keyframes spin {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}