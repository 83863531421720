.reviews-item {
  background-color: #fff;
  padding: 24px;
  border-radius: 5px;
  overflow: hidden;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__date {
    font-size: 12px;
    line-height: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__author {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: 767px) {
    margin-right: 16px;
    padding: 16px;
    width: 240px !important;

    &__author {
      font-size: 14px;
    }

    &__top {
      margin-bottom: 12px
    }
  }
}