.calling-designer {
  background-color: #FDF7F2;

  &__wrap {
    position: relative;
    padding-top: 80px;
    padding-bottom: 68px;
    padding-right: 370px;
  }

  &__content {
    max-width: 820px;
    min-height: 223px;
    margin-bottom: 46px;
  }

  &__title {
    font-size: 50px;
    line-height: 100%;
    font-family: $secondFont;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__descr {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 45px;
  }

  &__form {
    max-width: 696px;
    min-height: 207px;
  }

  &__form::after {
    content: '';
    display: block;
    background-image: url("../img/vendor/consult-photo.png");
    background-repeat: no-repeat;
    width: 383px;
    height: 554px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__features {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: minmax(0, 197px) minmax(0, 202px) 1fr;
    grid-gap: 0 50px;
  }

  &__features-item {
    position: relative;
    font-size: 16px;
    line-height: 19px;
    padding-left: 56px;
  }

  &__features-item::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../img/assets/icon-check-features.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #845249;
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  @media (max-width: 1024px) {

    &__content {
      max-width: none;
      margin-bottom: 110px;
    }

    &__wrap {
      padding-right: 30px;
      padding-bottom: 135px;
    }

    &__title {
      margin-bottom: 25px;
    }

    &__descr {
      margin-bottom: 52px;
    }

    &__features {
      grid-gap: 0 60px;
      grid-template-columns: minmax(0,207px) minmax(0,284px) minmax(0,294px);
    }

    &__form {
      max-width: 467px;
      margin-right: 497px;
    }

    &__form::after {
      right: 70px;
      width: 442px;
      height: 589px;
      background-image: url("../img/vendor/consult-photo-1024.png");
    }
  }

  @media (max-width: 992px) {

    &__wrap {
      padding-bottom: 50px;
    }

    &__title {
      margin-bottom: 27px;
      max-width: 636px;
    }

    &__content {
      margin-bottom: 37px;
    }

    &__form {
      max-width: 338px;
      margin-right: 350px;
    }

    &__form::after {
      background-image: url("../img/vendor/consult-photo-768.png");
      width: 354px;
      height: 458px;
      right: 58px;
    }
  }

  @media (max-width: 767px) {

    &__wrap {
      padding-right: 16px;
      padding-top: 40px;
    }
    
    &__title {
      font-size: 30px;
      line-height: 100%;
      margin-bottom: 20px;
    }

    &__descr {
      font-size: 16px;
      margin-bottom: 22px;
    }

    &__features {
      grid-template-columns: auto;
    }

    &__features-item:not(:last-child) {
      margin-bottom: 20px;
    }

    &__content {
      margin-bottom: 0;
    }

    &__form {
      margin-right: 0;
      position: relative;
      padding-top: 286px;
    }

    &__form::after {
      background-image: url("../img/vendor/consult-photo-767.png");
      width: 212px;
      height: 322px;
      right: 0;
      left: 0;
      margin: auto;
      bottom: auto;
      top: 0;
      z-index: 1;
    }
  }
}