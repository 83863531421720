.work-categories {
  padding-bottom: 100px;

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin: 0 auto;  
    max-width: 1110px;
  }

  &__item {
    display: block;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }

  &__item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all .2s;
  }

  &__item:hover::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__item img {
    max-width: 100%;
    display: block;
    pointer-events: none;
  }

  &__title {
    font-size: 50px;
    font-family: "Cormorant Garamond";
    font-style: italic;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    margin-top: 54px;
    margin-bottom: 43px;
  }

  @media (max-width: 992px) {
    &__content {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 767px) {

    &__title {
      font-family: $mainFont;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &__content {
      grid-template-columns: auto;
    }
  }
}