.modal {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s; 

  &__inner {
    max-width: 100px;
    min-width: 240px;
    max-height: 100%;
    left: 20px;
    right: 20px;
    background: #fff;
    position: absolute;
    z-index: 1;
    margin: auto;
    padding: 0;
    border-radius: 5px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  
  &-open {
    background-color: rgba(0, 0, 0, 0.8);
    transition: all .2s;
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }

  &-close {
    width: 28px;
    height: 28px;
    background-image: url("../img/assets/icon-close-modal.svg");
    position: absolute;
    border: none;
    right: 15px;
    top: 15px;
    background-color: transparent;
    cursor: pointer;
  }

  &--modal-callback &__inner {
    max-width: 540px;
  }

  &--work-example &__inner {
    max-width: 1440px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 767px) {
    &--work-example &-close {
      background-image: url("../img/assets/icon-arrow-back.svg");
      left: 15px;
      right: auto;
    }

    &--work-example &__inner {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;
    }
  }
}

