.consult-bottom {
  background-color: #F2F2F2;
  overflow: hidden;
  padding-top: 60px;

  &__wrap {
    background-image: url("../img/assets/consult-bg.jpg");
    background-repeat: no-repeat;
    background-position: left bottom;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__form {
    max-width: 540px;
    margin-bottom: -10px;
  }

  @media (max-width: 1024px) {
    &__form {
      max-width: 493px;
      margin-bottom: -25px;
    }
  }

  @media (max-width: 992px) {
    padding-top: 0;

    &__wrap {
      background-image: url("../img/assets/consult-bg-768.jpg");
      padding-top: 355px;
      background-position: top right;
    }

    &__form {
      max-width: 582px;
      margin-bottom: -2px;
      border-radius: 10px 10px 0 0;
    }
  }

  @media (max-width: 767px) {
    &__wrap {
      display: block;
      background-image: url("../img/assets/consult-bg-767.jpg");
      padding-top: 118px;
      padding-bottom: 30px;
    }
  }
}