.nav-header {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-evenly;

  &__item:not(:first-child){
    padding-left: 60px;
  }

  &__link {
    text-decoration: none;
    color: #000000;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
  }

  &__link:hover {
    color: #845249;
  }

  &__link:focus {
    color: #845249;
  }

  @media (max-width: 1024px) {
    &__item:not(:first-child){
      padding-left: 45px;
    }
  }

  @media (max-width: 767px) {
    display: block;
    width: 100%;

    &__item:not(:first-child) {
      padding-left: 0;
    }

    &__link {
      font-size: 12px;
      line-height: 131.523%;
      color: #ffffff;
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 24px;
      padding-right: 24px;
    }

    &__link:active,
    &__link:hover {
      color: #EDD0BA;
    }
  }
}