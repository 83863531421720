.form-consult {
  &__row {
    margin-bottom: 42px;
  }

  &__submit {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: .65px;
    padding: 20px 18px;
    width: 100%;
  }

  &__submit.submit-success {
    background-color: #0DC143;
    border-color: #0DC143;
    color: transparent;
    background-image: url("../img/assets/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
  }

  @media (max-width: 767px) {
    &__submit {
      font-size: 12px;
      line-height: 19px;
      padding: 11px 18px;
    }
  }
}