.form-calling-designer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,.1);
  border-radius: 10px;
  padding: 40px;

  &__policy {
    font-size: 12px;
    line-height: 16px;
    align-self: self-end;
  }

  &__policy a {
    color: #845249;
  }

  &__submit {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: .65px;
    padding: 20px 18px;
    width: 100%;
  }

  &__submit.submit-success {
    background-color: #0DC143;
    border-color: #0DC143;
    color: transparent;
    background-image: url("../img/assets/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
  }

  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-gap: 0;

    &__row {
      margin-bottom: 40px;
    }

    &__policy {
      margin-bottom: 32px;
    }
  }

  @media (max-width: 767px) {
    position: relative;
    z-index: 2;
    padding: 20px 20px 30px 20px;

    &__row {
      margin-bottom: 20px;
    }

    &__policy {
      margin-bottom: 20px;
    }

    &__submit {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      padding: 14px 18px;
      width: 100%;
    }
  }
}