.form-consult-questions {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 60px 60px 60px;

  &__title {
    font-family: $secondFont;
    font-weight: 700;
    font-style: italic;
    font-size: 50px;
    line-height: 100%;
    margin-bottom: 20px;
  }

  &__descr {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  &__row {
    margin-bottom: 30px;
  }

  &__row input {
    padding-bottom: 12px;
  }

  &__policy {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
  }

  &__policy a {
    color: #845249;
  }

  &__submit {
    padding: 20px 62px;
    font-weight: 600;
  }

  &__submit.submit-success {
    background-color: #0DC143;
    border-color: #0DC143;
    color: transparent;
    background-image: url("../img/assets/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
  }

  @media (max-width: 1024px) {
    padding: 40px 56px 56px;
  }

  @media (max-width: 767px) {
    padding: 20px;

    &__title {
      font-size: 30px;
      line-height: 100%;
      max-width: 228px;
    }

    &__descr {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: 20px;
    }

    &__policy {
      margin-top: 30px;
    }

    &__submit {
      font-size: 12px;
      line-height: 16px;
      padding: 14px 62px;
      width: 100%;
    }
  }
}