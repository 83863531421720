.work-slider {
  background-color: #fff;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all .4s;
  border-radius: 5px;
  padding-bottom: 80px;
  padding-top: 68px;

  &.open {
    visibility: visible;
    opacity: 1;
    transition: all .4s;
  }

  &__wrap {
    padding: 0 75px;
    max-width: 880px;
    max-height: 456px;
    overflow: hidden;
    margin: 0 auto;
  }

  &__item {
    overflow: hidden;
    border-radius: 5px;
  }

  &__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    max-height: 456px;
  }

  &__close {
    width: 28px;
    height: 28px;
    background-image: url("../img/assets/icon-close-modal.svg");
    position: absolute;
    border: none;
    right: 15px;
    top: 15px;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
  }

  .slick-prev {
    position: absolute;
    left: 0;
  }

  .slick-next {
    position: absolute;
    right: 0;
  }

  .slick-prev,
  .slick-next {
    top: 50%;
    margin-top: -22.5px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #845249;
    //background-color: #BDBDBD;
    background-image: url("../img/assets/icon-slider-arrow.svg");
    background-repeat: no-repeat;
    background-position: 14px center;
    color: transparent;
    border: none;
    cursor: pointer;
    transition: all .2s;
  }

  .slick-disabled {
    background-color: #BDBDBD;
  }

  .slick-next {
    transform: rotate(-180deg);
  }

  @media (max-width: 767px) {
    margin-left: -19px;
    margin-right: -30px;

    &__close {
      left: 15px;
      right: auto;
      background-image: url("../img/assets/icon-arrow-back.svg");
      background-color: #fff;
    }

    &__wrap {
      padding: 12px 0 0 0;
    }

    &__item {
      margin: 0 5px;
    }

    &__item img {
      height: 385px;
    }
  }
}