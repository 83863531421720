.footer {
  background-color: #432723;
  padding-top: 18px;
  padding-bottom: 18px;

  &__wrap {
    display: grid;
    grid-template-columns: minmax(0,289px) 1fr;
    grid-gap: 0 90px;
  }

  &__copyright, a {
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    text-decoration: none;
  }

  a:not(:first-child) {
    margin-left: 45px;
  }

  a:hover {
    color: #EDD0BA;
  }

  @media (max-width: 1024px) {
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 0;
    }
  }

  @media (max-width: 992px) {
    &__wrap {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: start;
      grid-gap: 50px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 8px;
    padding-bottom: 8px;

    &__wrap {
      grid-template-columns: auto;
      grid-gap: 0;
    }

    &__copyright {
      font-size: 12px;
      line-height: 18px
    }

    &__policy {
      font-size: 12px;
      line-height: 18px
    }

    &__policy a {
      display: inline-block;
    }

    &__policy a:not(:first-child) {
      margin-left: 0;
    }
  }
}